import React from 'react';
import { Button, ShowPopup, Popup, Row, Col, ClearIcon, AnnounceIcon, Typography} from '@commonsku/styles';
import config from '../config';
import { getIdentityUtils } from '../utils';
import { useIdentity } from '../hooks';

function StartDiscussion() {
  const identity = useIdentity();
  const { isAdmin, hasUserFlags, hasCapabilities } = getIdentityUtils(identity);

  return (
    <ShowPopup popup={StartDiscussionPopup} render={({ onClick }) => {
    return (
      <div onClick={() => {
        if ((hasUserFlags(['SKUMMUNITY_ENABLED']) || hasCapabilities(['ACCESS-SKUMMUNITY-FORUM']))) {
          onClick();
        }
      }}>
        <img
          style={{ verticalAlign: 'middle' }}
          src="/images/icons/publisher-discussion-icon.svg"
          height="40"
        />
        Start a Discussion
      </div>
    );
  }} />
  );
};

function StartDiscussionPopup({ onClose }) {
  return (
    <Popup onClose={onClose}
      style={{
        padding: '32px',
        width: '640px', height: '560px',
      }}
      popupContentStyle={{ height: '100%', padding: 0 }}
      header={
        <Row style={{ padding: 0 }}>
          <Col xs sm={8}>
            <Typography.Text bold fontSize={'24px'}>Start a Discussion</Typography.Text>
          </Col>
          <Col xs sm={4} style={{ textAlign: 'right' }}>
            <ClearIcon onClick={onClose} color='var(--color-primary1-main)' size='medium' />
          </Col>
        </Row>
      }>
      <Row style={{ padding: '24px 0', }}>
        <Col xs>
          <div style={{
            display: 'flex',
            padding: '24px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
            flex: '1 0 0',
            alignSelf: 'stretch',
            background: 'var(--color-neutrals-20)',
            height: '240px',
          }}>
            <AnnounceIcon />
          </div>
        </Col>
        <Col xs style={{ padding: '24px 0' }}>
          <Typography.Text fontSize="18px">
            Post your topic to the new skummunity Forum to collaborate, learn, and grow with fellow commonsku distributors.
          </Typography.Text>
        </Col>
        <Col xs>
          <Button
            variant='cta'
            style={{ width: '100%' }}
            onClick={() => {
              window.open(
                `${config.skummunity_base_url}/session/sso?return_path=${config.skummunity_return_path}`,
                '_blank'
              );
              onClose?.();
            }}
          >Take me to the skummunity Forum!</Button>
        </Col>
      </Row>
    </Popup>
  );
};

export default StartDiscussion;
